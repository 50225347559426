<template>
  <!-- 微信支付 -->
  <div class="wxPay">
    <!-- 面包屑 -->
    <a-breadcrumb separator=">" v-if="!$route.query.source">
      <a-breadcrumb-item
        ><router-link to="/knowledgeMall/shopHome"
          >知识商城</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item
        ><router-link to
          ><a @click="$router.go(-1)">收银台</a></router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">微信支付</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="box_con">
      <div class="con_left">
        <div class="left_info">您的订单已提交成功，请尽快完成付款！订单编号：{{orderDetail.code}}</div>
        <div class="left_con">
          <span class="con_title">微信支付</span>
          <span class="con_info" v-if="!isPastDue">二维码将会在 <span style="color: #EC6C01;">{{PastDueTime}}</span>秒后过期，过期后可刷新重新获取二维码。</span>
          <span class="con_info1" v-else>二维码已过期，<a @click="resetQrcode()">刷新</a>页面重新获取二维码。</span>
        </div>
        <div class="left_code">
          <!-- <iframe :src="payLink" frameborder="0"></iframe> -->
          <div id="qrcodeDiv" ref="qrcode"></div>
          <div v-if="isPastDue" class="code_past" @click="resetQrcode()">
            <!-- <a-icon type="reload" style="color: #fff; fontSize:50px;" /> -->
            <div class="load_txt">点击重新获取二维码</div>
          </div>
          <div class="code_past loadBox" v-show="loading">
            <a-spin class="load" size='large' />
          </div>
        </div>
        <div class="left_back" @click="goBack()">
          <a-icon type="left" style="color: #15B7DD;" />
          <span class="back_txt">更换支付方式</span>
        </div>
      </div>
      <div class="con_right">
        <div class="right_price">应付金额：<span class="light">{{(orderDetail.payPrice*100/100).toFixed(2)}}</span>元</div>
        <div class="right_img">
          <img src="@/assets/image/pay/hint.png" alt="扫一扫">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      orderId: null, //订单ID
      orderDetail: {}, //订单详情
      payLink: '', //微信支付链接
      loading: true, //加载

      qrCodeObj: {}, //二维码对象
      isPastDue: false, //二维码是否过期
      PastDueTime: 60, //过期时间一分钟

      isPayTimer: null, //是否支付定时器
      pastDueTimer: null, //是否过期定时器

      productType:'',// 订单类型  3.补考
      signupUserId: '', //考生 id
    }
  },
  // 事件处理器
  methods: {
    // 订单详情
    getOrderDetail() {
      this.$ajax({
        url: "/hxclass-pc/order/" + this.orderId,
        method: 'get',
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.orderDetail = res.data
        }else{
          this.$message.error(res.message);
        }
      })
    },
    // 获取支付链接
    getLink() {
      this.loading = true
      this.$ajax({
        url:"/hxclass-pc/order/native/pay",
        method:'post',
        params:{
          orderId: this.orderId, //订单id
          payMethod: 1, //1微信 2支付宝
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.loading = false
          this.payLink = res.data.codeUrl
          this.isPastDue = false;
          this.PastDueTime = 60;
          // 二维码是否过期
          this.getIsPastDue();
          // 生成二维码
          this.getQrcode();
        }else{
          this.$message.error(res.message);
        }
      })
    },
    // 生成二维码
    getQrcode() {      
      this.qrCodeObj = new QRCode(qrcodeDiv, {
        text: this.payLink, // 用于生成二维码的文本
        width: 260, // 高度
        height: 260, // 宽度
        colorDark: '#000000', //前景色
        colorLight: '#ffffff', //后景色
      })
    },
    // 是否支付成功
    isPay() {
      let url = ''
      // 补考
      if(this.productType == 3){
        url = "/hxclass-pc/exam/signUp/selMakUpOrderIsPay?orderId=" + this.orderId + '&signUpUserId=' + this.signupUserId
      } else {
        url = "/hxclass-pc/order/is-pay/" + this.orderId
      }
      this.$ajax({
        url: url,
        method: 'get',
      }).then(res=>{
        if(res.code == 200 && res.success){
          // 支付成功
          this.$router.replace({
            path: '/pay/payOk?type=1',
            query: {
              'orderId': this.$AES.encode_data(this.orderId),
              productType: this.$route.query.productType,
              auditTime: this.$route.query.auditTime,
              source: this.$route.query.source
            },
          })
          clearInterval(this.pastDueTimer); //是否过期定时器
          clearInterval(this.isPayTimer); //是否支付定时器
        }else{
          // 未支付
          this.isPayTimer = setTimeout(() => {
            this.isPay();
            clearInterval(this.isPayTimer)
          }, 3000)
        }
      })
    },
    // 二维码是否过期
    getIsPastDue() {
      this.pastDueTimer = setInterval(() => {
        this.PastDueTime -- 
        // 二维码过期
        if (this.PastDueTime == 0) {
          this.isPastDue = true
          clearInterval(this.pastDueTimer)
        }
      }, 1000)
    },
    // 刷新二维码
    resetQrcode() {
      // // 清除二维码
      this.$refs.qrcode.innerHTML = '';
      // 获取支付链接
      this.getLink();
    },
    // 更换支付方式
    goBack() {
      this.$router.go(-1)
    }
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.orderId = this.$AES.decode_data(this.$route.query.orderId);

    this.productType = this.$route.query.productType ? this.$AES.decode_data(this.$route.query.productType) : 0; //
    this.signupUserId = this.$route.query.signupUserId ? this.$AES.decode_data(this.$route.query.signupUserId) : 0;

    // 订单详情
    this.getOrderDetail();
    // 微信支付链接
    this.getLink();
    // 是否支付成功
    let timer1 = setTimeout(() => {
      this.isPay();
      clearTimeout(timer1)
    }, 3000)
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () {
    // 销毁支付监听
    clearInterval(this.isPayTimer)
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  * {box-sizing: border-box;}
  .wxPay{
    width: 1400px;
    margin: 0 auto;
    padding-top: 92px;
    .box_con {
      display: flex;
      margin-top: 22px;
      margin-bottom: 40px;
      padding: 40px 40px 60px 40px;
      background: #fff;
      border-radius: 5px;
      .con_left {
        .left_info {
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }
        .left_con {
          margin-top: 54px;
          .con_title {
            font-size: 24px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 500;
            color: #333333;
            margin-right: 20px;
          }
          .con_info {
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
          .con_info1 {
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #EC6C01;
            a {
              color: #4884FF;
              text-decoration: underline;
            }
          }
        }
        .left_code {
          position: relative;
          width: 300px;
          height: 300px;
          padding: 20px;
          border: 1px solid #EBEBEB;
          margin-top: 33px;
          margin-left: 180px;
          img {
            width: 100%;
            height: 100%;
          }
          .code_past {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 20px;
            top: 20px;
            width: 260px;
            height: 260px;
            background: rgba(0,0,0,0.7);
            cursor: pointer;
            .load_txt {
              background-color: #f5f5f5;
              padding: 10px 20px;
              // font-size: 14px;
            }
          }
          .loadBox {
            background: #fff;
          }
        }
        .left_back {
          display: inline-block;
          margin-top: 50px;
          cursor: pointer;
          .back_txt {
            font-size: 20px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #15B7DD;
          }
        }
      }
      .con_right {
        margin-left: 234px;
        .right_price {
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-left: 120px;
          .light {
            color: #EC6C01;
            font-size: 28px;
          }
        }
        .right_img {
          margin-top: 106px;
          img {
            width: 234px;
          }
        }
      }
    }
  }
  // 小屏分辨率内容区呈现1200px布局
  @media screen and (max-width: 16000px) {
    .wxPay {
      width: 1200px;
    }
  }
</style>
